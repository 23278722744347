@import "../../../styles/fonts.scss";
@import "../../../styles/variables.scss";
.tble-pgntn-container {
  .img-container {
    height: 24px;
    width: 24px;
    .image-icon {
      filter: invert(91%) sepia(5%) saturate(7%) hue-rotate(347deg) brightness(89%) contrast(82%);
    }
    &.active {
      cursor: pointer;
      .image-icon {
        filter: invert(42%) sepia(51%) saturate(2060%) hue-rotate(166deg) brightness(90%) contrast(86%);
      }
    }
  }
  .text-wrapper {
    margin-left: 8px;
    margin-right: 8px;
    font-family: "Open Sans Regular";
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
    color: $pure_black;
  }
}
