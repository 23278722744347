/*New UI fonts*/
@font-face {
  font-family: "Open Sans Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Regular"),
    url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
  line-height: 1;
}

@font-face {
  font-family: "Open Sans Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Medium"),
    url("../assets/fonts/OpenSans-Medium.ttf") format("truetype");
  line-height: 1;
}

@font-face {
  font-family: "Open Sans Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Semibold"),
    url("../assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  line-height: 1;
}

@font-face {
  font-family: "Open Sans bold";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans bold"),
    url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
  line-height: 1;
}

@font-face {
  font-family: "Open Sans italic";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans italic"),
    url("../assets/fonts/OpenSans-Italic.ttf") format("truetype");
  line-height: 1;
}

@font-face {
  font-family: "Open Sans SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans SemiBold Italic"),
    url("../assets/fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
  line-height: 1;
}
