@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";
.notification-container-new {
	font-size: 14px;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999;
  font-size: 12px;
  font-family: "Open Sans Regular";
  
 
}

.top-right-new {
	top: 15%;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.bottom-right {
	bottom: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.top-left {
	top: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.bottom-left {
	bottom: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.notification-new {
	background: #fff;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 20px;
	margin-bottom: 4px;
	width: 300px;
	max-height: 100px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;
  opacity: 1;
  &.green-notification {
    border-left: 3px solid $col-notification-green-border;
    background-color: $col-notification-green-bg;
  }
  &.blue-notification {
    border-left: 3px solid $col-notification-blue-border;
    background-color: $col-notification-blue-bg;
  }
  &.read-notification {
    border-left: 3px solid $pure_gray;
    background-color: $gray;
  }
  &.red-notification {
    border-left: 3px solid $col-notification-red-border;
    background-color: $col-notification-red-bg ;
  }
}

.notification-new:hover {
	box-shadow: 0 0 12px #fff;
	opacity: 1;
	cursor: pointer
}

.notification-title {
	font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 6px;
    width: 300px;
    height: 18px;
}

.notification-message {
	// margin: 0;
	// text-align: left;
    // height: 18px;
    // margin-left: -1px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
	max-height: 100px;
    font-family: "Open Sans Regular";
    font-size: 12px;
    word-break: break-all;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.notification-image {
    float: left;
    margin-top: 4%;
}

.notification-image img {
    width: 30px;
    height: 30px;
}

.toast {
    height: 50px;
    width: 365px;
	color: #fff;
	padding: 20px 15px 10px 10px;
}

.notification-container-new button {
	position: relative;
	right: -.3em;
	top: -.3em;
	float: right;
	font-weight: 700;
	color: grey;
	outline: none;
	border: none;
	text-shadow: 0 1px 0 #fff;
	opacity: .8;
	line-height: 1;
	font-size: 16px;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}



.icon-read {
  filter: invert(48%) sepia(5%) saturate(21%) hue-rotate(325deg) brightness(95%)
    contrast(94%);
}

.icon-tick {
  background: icon("Icons-Check round"); // padding-left: 17px;
  margin-right: 11px;
  width: 24px;
  height: 24px;
}
.icon-errortick {
  background: icon("icon-close-round"); // padding-left: 17px;
  margin-right: 11px;
  width: 24px;
  height: 24px;
}
.icon-loading {
  background: icon("icon-loading"); // padding-left: 17px;
  padding-right: 17px;
  margin-right: 17px;
  width: 24px;
  height: 24px;
}

.notification-content {
	align-self: center;
	.message-first-class {
	  display: flex;
	  align-items: baseline;
	}
	.message-second-class {
	  display: flex;
	  justify-content: space-between;
	  color: $btn_blue;
	  align-items: baseline;
	  .time-class {
		font-family: "Open Sans Semibold";
		font-size: 10px !important;
		font-weight: bold;
		color:#1437CC;
	  }
	}
	.view-detail-class {
	  color: $btn_blue;
	  text-decoration: underline;
	  cursor: pointer;
	}
	.view-detail-errorclass{
	  color: $btn_blue;
	}
  }
  .message-name {
	max-height: 100px;
	font-family: "Open Sans Regular";
	font-size: 14px;
	word-break: break-all;
	margin-top: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	margin-top: 0px !important;
  }
  .text-bold-new {
	font-weight: bold;
	font-family: "Open Sans Semibold";
	font-size: 16px;	
  }