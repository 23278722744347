@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/functions.scss";
@import "../../../../../../styles/fonts.scss";

.simple-filter-select-wrapper {
  // background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #606060;
  // border-radius: 4px;
  // opacity: 1;
  // display: flex;
  // justify-content: space-between;
  // height: 40px;
  // align-items: center;
  // cursor: pointer;
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0000000a;
  border: 1px solid $col-tag-bg;
  border-radius: 8px;
  opacity: 1;
  .simple-filter-selected-name {
    font-size: 14px;
    letter-spacing: 0px;
    color: #888888;
    opacity: 1;
    padding-left: 15px;
    &.ellipse-container {
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .border-chevron-wrapper {
    border: 1px solid #24a3bc;
    padding: 8px;
    width: 8px;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 16px;
  }
}

@media only screen and (min-width: 1700px) {
  .simple-filter-select-wrapper {
    .simple-filter-selected-name {
      &.ellipse-container {
        width: 90%;
        white-space: normal;
      }
    }
  }
}
