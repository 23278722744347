@import "../../../styles/variables.scss";

.loader-parent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;

  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $pure_white;
    opacity: 0.7;
  }
}
