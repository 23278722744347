@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.notifications-list-wrapper {
  position: absolute;
  top: 50px;
  right: 218px;

  width: min-content !important;
  z-index: 10;
  .notifications-list-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 305px;
    border-radius: 6px 6px 0px 0px;
  }
  .show-all-class {
    @media (max-width: 1399px) {
      max-height: 560px;
    }
    max-height: 794px;
    height: auto;
  }

  background-color: $pure_white;
  border-radius: 6px;
  .notification-list-tost-container {
    position: inherit !important;
    width: 285px;
    background: $col-alert;
    align-items: center;
    // height: 35px;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    // width: auto !important;
    height: auto;
    // position: absolute;

    // box-shadow: 0px 3px 6px #00000029;
    // border-left: 3px solid #000000;
    min-height: 50px;
    max-width: 350px;
    padding: 8px 0 7px 12px;
    .message-class {
      margin-top: 0px !important;
    }

    &.green-notification {
      border-left: 3px solid $col-notification-green-border;
      background-color: $col-notification-green-bg;
    }
    &.blue-notification {
      border-left: 3px solid $col-notification-blue-border;
      background-color: $col-notification-blue-bg;
    }
    &.read-notification {
      border-left: 3px solid $pure_gray;
      background-color: $gray;
      // img {
      //   filter: invert(48%) sepia(5%) saturate(21%) hue-rotate(325deg)
      //     brightness(95%) contrast(94%);
      // }
    }
    &.red-notification {
      border-left: 3px solid $col-notification-red-border;
      background-color: $col-notification-red-bg ;
    }
    div {
      font-size: 12px !important;
    }
  }
  .view-all-button-class {
    width: 100%;
    // border: 1px solid #149ccc;
    border-radius: 0px 0px 6px 6px;
  }
}
