@import "../../../styles/variables.scss";
@import "../../../styles/functions.scss";

.menu-section {
  // padding-left: 28px;
  height: 100%;
  a {
    text-decoration: none;
  }
  .menu-left-pad {
    // width: 2%;
    width: 39px;
    // border-bottom: 1px solid $header_border;
  }
  .menu-right-pad {
    // border-bottom: 1px solid $header_border;
  }
  .active-menu-right-pad {
    width: 30px;
    // border-bottom: 1px solid $header_border;
  }

  .active-menu-item {
    float: left;
    position: relative;
    background: #fff;

    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // box-shadow: 1px 56px 5px 2px white;
    box-shadow:0px 2px 3px white
  }

  .active-menu-item:before,
  .active-menu-item:after {
    content: "";
    position: absolute;

    height: 17px;
    width: 21px;

    bottom: -1px;
  }

  .active-menu-item:after {
    right: -20px;

    border-radius: 0 0 0 10px;
    -moz-border-radius: 0 0 0 10px;
    -webkit-border-radius: 0 0 0 5px;

    -webkit-box-shadow: -10px 0 0 0 #fff;
    // box-shadow: -10px 0 0 0 #fff;
    box-shadow: inset 1px 0px 1px 0 #dde5ea;
  }

  .active-menu-item:before {
    left: -20px;

    border-radius: 0 0 10px 0;
    -moz-border-radius: 0 0 10px 0;
    -webkit-border-radius: 0 0 5px 0;

    -webkit-box-shadow: 10px 0 0 0 #fff;
    // box-shadow: 10px 0 0 0 #fff;
    box-shadow: inset -1px -1px 0 0 #dde5ea;
  }
  .active-menu-link {
    // padding-right: 30px;
  }
  .menu-item {
    height: 67px;
    // border-bottom: 1px solid $header_border;

    &.active-menu-item {
      background-color: $pure_white;
      border-radius: 4px;
      border-bottom: none;
    }

    .menu-label {
      width: fit-content;
      // padding-left: 15px;
      padding-right: 30px;
      @media (max-width: 1248px) {
        // padding-left: 10px;
        // padding-right: 10px;
        // font-size: 15px;
      }
      padding-top: 18px;
      color: #9aabb7;
      font-size: 18px;
      font-family: "Open Sans bold" !important;
      // letter-spacing: 1.44px;
      letter-spacing: 0.55px;
      display: flex;

      &.active-menu-label {
        padding-left: 16px;
        padding-right: 16px;
        color: $btn_blue;
        border-top: 1px solid #dde5ea;
        border-left: 1px solid #dde5ea;
        border-right: 1px solid #dde5ea;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding-bottom: 20px;
        img {
          padding-left: 2px;
          filter: invert(66%) sepia(39%) saturate(5779%) hue-rotate(158deg)
            brightness(86%) contrast(85%);
        }
      }

      &:hover {
        color: $btn_blue;
        img {
          filter: invert(66%) sepia(39%) saturate(5779%) hue-rotate(158deg)
            brightness(86%) contrast(85%);
        }
      }
    }

    img {
      // filter: invert(77%) sepia(26%) saturate(168%) hue-rotate(162deg) brightness(83%) contrast(92%);
      filter: invert(79%) sepia(14%) saturate(309%) hue-rotate(162deg)
        brightness(83%) contrast(91%);
    }
  }
  .sub-menu {
    background-color: $pure_white;
    position: absolute;
    box-shadow: 0px 3px 6px #00000033;
    border-radius: 4px;
    opacity: 1;
    // width: 258px;
    width:20rem;
    z-index: 2;

    .sub-menu-option {
      border-bottom: 1px solid $dropdown_border_color;
      padding: 16px;
      font-size: 16px;
      color: #645d5d;
    }
    .active-submenu-item {
      color: $btn_blue;
    }
  }
}
