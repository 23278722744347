@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";
@import "../../../../styles/fonts.scss";

.dropdown-wrapper {
  min-width: 318px;
  display: flex;
  flex-direction: column;
  .category-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 61px;
    padding-right: 16px;
    .category-filter-name {
      font-size: 16px;
      padding-left: 30px;
      color: $pure_black;
      letter-spacing: 0px;
      opacity: 1;
      font-family: "Open Sans Semibold" !important;
    }
    .dnd-title {
      letter-spacing: 0px;
      color: #4a4d4e;
      opacity: 1;
      font-size: 12px;
      font-family: Open Sans Medium;
    }
  }
}
