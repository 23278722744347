@import "../../../styles/variables.scss";
.custom-toggle-group {
  display: flex;
  justify-content: space-between;
  .custom-toggle-btn {
    font-family: "Open Sans bold";

    flex-grow: 1;
    padding: 8px 20px;
    border-color: #1a9de2;
    background-color: white;
    color: #1a9de2;
    font-size: 0.7rem;
    text-transform: none;
    width: calc(50% - 16px);
  }
  .custom-toggle-btn.selected {
    color: white;
    background-color: #1a9de2 !important;
  }
}
