@import "../../../../../../../styles/variables.scss";
@import "../../../../../../../styles/functions.scss";
@import "../../../../../../../styles/fonts.scss";

.survey-filter-name {
  font-family: Open Sans Semibold;
  font-size: 16px;
  letter-spacing: 0px;
  //font-weight: 600;
  color: $pure_black;
  opacity: 1;
  width: 10%;
}

.survey-filter-question {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #554e4e;
  opacity: 1;
  width: 50%;
}
