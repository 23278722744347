$assetPath: "/assets/";

/* Colors - START */
$gray: #d8d8d8;
$pure_black: #000000;
$pure_white: #ffffff;
$primary_blue: #05759f;
$secondary_blue: #103a78;
$light_pink: #c38ebd;
$border-pink: #ba7db4;
$primary_pink: #e64974;
$light_blue: #36bbec;
$light_green: #6ab42d;
$primary_gray: #9e9e9e;
$secondaty_gray: #d9d9d9;
$pure_gray: #767676;
$body_background: #fafafa;
$heading_text: rgba(0, 0, 0, 0.76);
$pragraph_small_color: #a9a9a9;
$button_blue: #00bcff;
$border_blue: #45bed5;
$btn_blue: #149ccc;
$primary_btn_gray: #969696;
$primary_border_gray: #f4f4f4;
$secondary_btn_gray: #9d9d9d;
$secondary_border_gray: #cccccc;
$tertiary_btn_gray: #a7a7a7;
$tile_gray: #fcfcfc;
$tile_border_gray: #f2f2f2;
$tile_bg_gray: #a2c3c9;
$tile_text_gray: #6a6a6a;
$tile_connect_gray: #c9c9c9;
$tile_border_blue: #1699c7;
$input_border_gray: #dfe2e5;
$input_text_gray: #645d5d;
$dropdown_hover_gray: #f8f9fa;
$tile_label_blue: #1896c3;
$tile_border_blue: #1699c7;
$input_border_gray: #dfe2e5;
$input_text_gray: #645d5d;
$dropdown_border: #dfe2e5;
$placeholder_gray: #6e757c;
$error_text_red: #ac1111;
$error_border_red: #db1414;
$input_inactive_bd_gray: #e9ecef;

$email_bg_color: #d7e5f8;
$newsletter_bg_color: #d7f8d8;
$events_bg_color: #f8d7E4;
$subTite_gray: #4b4b4b;
$color_primary: #36bbec;
$color_secondary: #767474;
$text_danger: #e57272;
$text_info: $color_primary;
$text_success: #4eb51f;
$menu_active: #00bcff;
$menu_inactive: #767474;
$tile_border: #a3a1a1;
$paragraph_color: $secondary_blue;
$checkbox-color: #848484;
$chart_danger_border: #e64974;
$chart_danger_bg: #fdeaef;
$chart_success_border: $text_success;
$chart_success_bg: #b9f0a8;
$popup_border_gray: $checkbox-color;
$level3_border: #dbdbdd;
$gray_border: #707070;
$page-heading-border: $level3_border;
$checkbox_border_color: #999999;
$bayer_snow_bg: #76abc7;
$toggle_bg_color: #f2f1f1;
$toggle_border_color: #ddd;

$search_result_border: #bebbbb;
$filter_result_box_border: #707070;
$gray_text_small: $checkbox-color;
$text-red: #e94773;
$alert_red: #ff0000;
$dropdownitem_color: #645d5d;
$dropdown_heading_color: #333333;
$dropdown_checkbox_color: #999999;
$dropdown_border_color: #e3e6e9;
$dropdown_segment_color: #5c5c5c;
$label_border: #d8d8d8;
$header_border: #dde5ea;
$background_gray: #c6c6c6;
$arrow_blue: #00bcff;
$dark-gray: #b5a5a5;
$box-shadow-gray: #00000029;
$white-smoke: #f7f7f7;
$dropdowndown_inactive_background: #f8f9fa;
$popup_download_text: #2d9de2;
/* Colors - END */

/*New UI colors*/
$col-pink: #f5c8c8;
$col-light-blue: #30bdff;
$col-light-grey: #d7d8d7;
$col-alert: #ffd75e;
$col-bg-gray: #f7f7f7;
$col-border-gray: #e0e0e0;
$col-active-dark-blue: #11587e;
$col-arrow-link: #23a8de;
$col-gray-text: #bfbfbf;
$col-notification: #ff0000;
$col-mandatory-text: #67389a;
$col-gray-border: #e3e3e3;
$col-level2-bar: #1a9de2;
$col-tag-bg: #e6e6e6;
$col-tag-boder: #e3e6e9;
$col-notification-green-border: #309f79;
$col-notification-green-bg: #e8fcdf;
$col-notification-blue-border: #3e63f2;
$col-notification-blue-bg: #dfe5fc;
$col-notification-red-border: #a11616;
$col-notification-red-bg: #ffcbcb;
$blue_background: #38abd5;
$col-dropdown-border: #b7b7b7;
$col-table-header-bg: #e9ecef;
$col-table-heading: #194393;
$col-view-target-btn-bg: #457cb3;
$col-new-label-bg: #f78a8a;
$col-view-target-btn-bg: #457cb3;
$col-priority-critical: #ff2424;
$col-priority-major: #c4680d;
$col-td-border: #cfd4d9;
$col-table-body-text: #4a5056;
$col-table-header-text: #167ea3;
$col-recommend-btn-bg: #cc8683;
$col-mandatory-text-shipment: #67389a;
$col-target-size-label-bg: #edefbb;
$col-extra-info-text: #6f6f6e;
$col-sugg-arrow-border: #d8dde6;
$col-sugg-card-bg: #fffdfd;
$col-status-connect-blue: #58bce0;
$col-file-container-bdr: #c1c9cc;
$col-mccp-border: #f5f5f5;
$col-mccp-text: #389b26;
$col-mccp-tag: #439a32;
$col-mccp-total: #677f9d;
$col-ga-grey: #7d7d7d;
$col-switch: #cddcf2;
$col-download: #0f8cc6;
$col-number-blue: #0b5476;
$col-number-red: #e32020;
$col-number-yellow: #deb21f;
$col-number-orange: #f57d06;
$col-number-indigo: #5126e2;
$col-border-light-grey: #e2e2e2;
$col-toggle-bg: #b8e5f5;
$col-dashboard-disabled-bdr: #B4B5B5;
$col-dashboard-disabled-bg: #C7C7C7;
$col-dashboard-disabled-text: #2B383C;
$col-dashboard-txtarea-disabled-bdr: #B5B6B7;
$col_demographic-text: #727272;
$col-loading-para: #6e6e6e;
/*typography- START*/

$fontsize_h1: 36px;
$fontsize_h2: 32px;
$fontsize_h3: 24px;
$fontsize_h4: 18px;
$btn_font_size: 16px;
$paragraph_small_size: 12px;
$checkbox_font-size: 14px;
$checkbox_heading_size: 20px;
$level_heading_size: 22px;
$base_z_index: 2;
$dropdownitem_font_size: 14px;
/*Typography -END*/

/*Header - START*/
$header_background: #f7f9fb;
/*Header - ENd*/

/* CSS breakpoints and media query variables - Start */

$screen-xs-min: 320px;
$screen-xs-max: 480px;
$screen-sm-min: 481px;
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 1024px;
$screen-lg-min: 1025px;
$screen-lg-max: 1280px;
$screen-xl-min: 1281px;

/* Device specific resolutions */
$mobile-portrait: "min-width: #{​​​​​​​​$screen-xs-min}​​​​​​​​) and (max-width: #{​​​​​​​​$screen-xs-max}​​​​​​​​";
$mobile-landscape: "min-width: #{​​​​​​​​$screen-sm-min}​​​​​​​​) and (max-width: #{​​​​​​​​$screen-sm-max}​​​​​​​​";
$ipad-portrait: "min-width: #{​​​​​​​​$screen-md-min}​​​​​​​​) and (max-width: #{​​​​​​​​$screen-md-max}​​​​​​​​";
$ipad-landscape: "min-width: #{​​​​​​​​$screen-md-min}​​​​​​​​) and (max-width: #{​​​​​​​​$screen-md-max}​​​​​​​​ and (orientation: landscape)";
$laptop: "min-width: #{​​​​​​​​$screen-lg-min}​​​​​​​​) and (max-width: #{​​​​​​​​$screen-lg-max}​​​​​​​​";
$desktop: "min-width: #{​​​​​​​​$screen-xl-min}​​​​​​​​)";

/* Collection of devices */
$smaller-than-desktop: "max-width: #{​​​​​​​​$screen-lg-max}​​​​​​​​";
$ipad-and-smaller: "max-width: #{​​​​​​​​$screen-md-max}​​​​​​​​";
$mobile-landscape-and-smaller: "max-width: #{​​​​​​​​$screen-sm-max}​​​​​​​​";

/* CSS breakpoints and media query variables - End */
