@import "../../../styles/variables.scss";

.layout-container {
  position: relative;
  height: 100vh;

  .header-container {
    height: 80px;
    width: 100%;
    // border-bottom: 1px solid $header_border;
    background: $header_background 0% 0% no-repeat padding-box;
    /*  position: fixed;
    top: 12%; */
    position: relative;
  }
  .breadcrumb-container {
    background-color: $pure_white;
    box-shadow: 0px 3px 3px #42536616;
    height: 80px;
  }
  .right-container {
    background: $white-smoke;
    // height: calc(100vh - 160px);
    height: calc(100vh - 80px);    
    overflow-y: auto;    
  }

  .page-container {
    // padding: 0px 10px 30px 10px;
    padding: 0px 30px;
  }
  .error-message {
    text-align: center;
    margin-top: 15%;
    .message-text {
      color: $color_secondary;
    }
    .error-icon {
      width: 70px;
      height: 70px;
      fill: $color_primary;
    }
  }
  .unavailable-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.5),
      1px 1px 1px rgba(0, 0, 0, 0.1);

    .unavailable-text {
      color: $pure_black;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 8px 12px;
      font-weight: bold;
    }
  }
  .box-shadow {
    background: $pure_white 0% 0% no-repeat padding-box;
    border: 1px solid $col-border-gray;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .layout-container {
    position: relative;
  }
}
.header-container-new {
  height: 80px;
  width: 100%;
  // border-bottom: 1px solid $header_border;
  background: $header_background 0% 0% no-repeat padding-box;
 // box-shadow: 0px 3px 3px #42536610;
  /*  position: fixed;
    top: 12%; */
  // position: relative;
  position: fixed;
  top: 0;
  z-index: 1000; //
}
.apps-container-iframe {
  margin-top: 80px;
}
