@import "../../../../../../../styles/variables.scss";
@import "../../../../../../../styles/functions.scss";
@import "../../../../../../../styles/fonts.scss";


.first-subgroup-container-survey {
  border: 1px solid #e1e1e0;
  padding: 12px;
  box-shadow: 3px 3px 10px #00000022;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
}

.overlay {
  .popup-wrapper {
    &.del-confrmtn-popup {
      max-width: 650px;
      .popup-confirm-msg {
        margin-top: 20px;
        margin-left: 35px;
        font-family: "Open Sans Semibold";
        font-size: 18px;
        line-height: 1.37;
        color: $pure_black;
      }
      .popup-content {
        margin-top: 8px;
        margin-left: 35px;
        font-family: "Open Sans Regular";
        font-size: 16px;
        line-height: 1.87;
        color: $pure_black;
      }
      .custom-button-wrapper {
        margin-right: 24px;
        margin-bottom: 24px;
        margin-top: 20px;
        .btn {
          background-color: $blue_background;
          border-color: $blue_background;
          padding: 10px;
          font-family: "Open Sans bold";
          font-size: 14px;
          line-height: 1.35;
        }
        .btn.secondary {
          color: #149ccc;
          background-color: #ffffff;
          border-color: #149ccc;
        }
      }
    }
  }
}
