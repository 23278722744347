@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/functions.scss";
@import "../../../../../../styles/fonts.scss";

.subgroup-not-expanded-container {
  background: #fafafa 0% 0% no-repeat padding-box;
  border-top: 1px dashed #d0d0d0;
  border-bottom: 1px dashed #d0d0d0;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .group-not-expanded-name {
    font-family: Open Sans Semibold;
    font-size: 16px;
   // font-weight: 600;
    letter-spacing: 0px;
    color: $secondary_btn_gray;
    opacity: 1;
    padding-left: 24px;
  }
  &.no-border {
    border-top: none;
  }
}
