@import "../../../../../../../styles/variables.scss";
@import "../../../../../../../styles/functions.scss";
@import "../../../../../../../styles/fonts.scss";

.past-interaction-title-wrapper {
  background: $btn_blue 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: inline-block;
  padding: 4px;
  margin-left: 8px;
  .past-interaction-title {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-family: Open Sans Semibold;
  }
}
.past-interaction-mandatory {
  display: flex;
  justify-content: flex-end;
  color: $col-mandatory-text;
  font-family: Open Sans Semibold;
  font-size: 14px;
 // font-weight: 600;
  line-height: 14px;
  margin-right: 16px;
}
