@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";
@import "../../../../../styles/fonts.scss";

.filter-wrapper {
  width: 318px;
  height: 48px;
  border-bottom: 1px dashed #918888;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
