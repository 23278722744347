@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";
@import "../../../../styles/fonts.scss";

.footer-container {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 16px;
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #00000029;
  border-radius: 0px 0px 28px 28px;
  opacity: 1;
  height: 80px;
}
