@import "../../../../styles/variables.scss";
@import "../../../../styles/functions.scss";
@import "../../../../styles/fonts.scss";

.input-wrapper {
  display: flex;
  justify-content: center;
  background: $col-mccp-border 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 318px;
  height: 61px;
  align-items: center;
  .input-box {
    display: flex;
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #0000000f;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    opacity: 1;
    width: 270px;
    height: 37px;
    justify-content: space-between;
    .input-section {
      font-size: 12px;
      letter-spacing: 0px;
      color: $checkbox-color;
      opacity: 1;
      border: 0;
      outline: none;
      padding-left: 14px;
    }
  }
}
