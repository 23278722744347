@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/functions.scss";
@import "../../../../../../styles/fonts.scss";

.multi-simple-filter-select-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;
  .simple-filter-multi-selected-name {
    font-size: 14px;
    letter-spacing: 0px;
    color: #888888;
    opacity: 1;
    padding-left: 15px;
  }
  .operator-between-filters {
    color: $pure_black;
    font-size: 12px;
    font-weight: 600;
  }
  .more-filters-selected {
    color: #2d9de2;
    font-size: 12px;
    font-weight: 600;
  }
}
