@import "../../../../../styles/variables.scss";
@import "../../../../../styles/functions.scss";
@import "../../../../../styles/fonts.scss";

.simple-filter-wrapper {
  transition: background-color 0.8s ease-out;
  border: 0.5px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    transition: background-color 0.1s ease-in;
  }
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  height: 46px;
  width: 318px;
  .simple-filter-name {
    color: $pure_black;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
    padding-left: 30px;
    font-family: Open Sans Regular;
  }
  .icons-container {
    display: flex;
    align-items: center;
    padding-right: 14px;
    .image-box {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.toolTip-container {
  .btn {
    align-items: center;
  }
  a {
    padding-left: 5px;
    color: #149ccc;
  }
}
