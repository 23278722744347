@import "../../../styles/variables.scss";
.tag-container {
  //height: 37px;
  width: auto;
  padding: 8px 5px;
  border-radius: 4px;
  background: $col-tag-bg;
  //border: 1px solid $label_border;
  display: inline-flex;
  /* margin-inline-end: 12px; */
  font-size: 12px;
  position: relative;
  justify-content: center;
  align-items: center;
  // font-family: 'Open Sans';

  .value-field {
    font-weight: normal;
    text-transform: uppercase;
    margin-left: 5px;
    vertical-align: middle;
  }

  .refresh-icon {
    width: 15px;
    height: 15px;
    color: $primary_blue;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
  }
  .country-icon {
    width: 18px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
  }
}
